var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"containerbar"}},[_c('div',{staticClass:"rightbar"},[_c('div',{staticClass:"breadcrumbbar"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 col-lg-12"},[_c('h4',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t("profile.supplies")))]),_c('div',{staticClass:"breadcrumb-list"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/dashboard"}},[_vm._v(" "+_vm._s(_vm.$t("profile.home"))+" ")])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t(_vm.title)))])])])])])])]),_c('div',{staticClass:"contentbar"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-xl-6"},[_c('div',{staticClass:"card pb-5"},[_c('v-card-title',[_c('h5',{staticClass:"card-title"},[_c('i',{staticClass:"feather icon-user mr-2"}),_vm._v(" "+_vm._s(_vm.$t(_vm.titleAction))+" ")]),_c('v-spacer'),_c('v-spacer')],1),_c('v-card-text',[_c('v-container',[_c('validation-observer',{ref:"observer1",attrs:{"disabled":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.isView,"counter":100,"error-messages":errors,"label":_vm.$t('label.name'),"required":"","autocomplete":"off"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.isView,"counter":200,"error-messages":errors,"label":_vm.$t('label.lastname'),"required":"","autocomplete":"off"},model:{value:(_vm.model.lastname),callback:function ($$v) {_vm.$set(_vm.model, "lastname", $$v)},expression:"model.lastname"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"email","rules":"required|max:60|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.isView,"data-vv-as":"emai","type":"email","counter":60,"error-messages":errors,"label":_vm.$t('label.e-mail'),"required":"","autocomplete":"off"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])})],1)]),_c('br')])],1)],1)],1)]),_c('div',{staticClass:"col-lg-6 col-xl-6"},[_c('div',{staticClass:"card"},[_c('AlertMessageComponent',{ref:"alertMessageComponent"}),_c('v-card-text',[(_vm.withPermissionName(_vm.permissions.PRO_UPD_PASS_01))?_c('div',{attrs:{"id":"form"}},[_c('validation-observer',{ref:"observer2",attrs:{"disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',{staticClass:"card-title"},[_c('i',{staticClass:"feather icon-lock mr-3"}),_vm._v(_vm._s(_vm.$t("profile.change_password"))+" ")])])]),_c('v-container',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"password","rules":"required|max:50|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"new-password","counter":50,"append-icon":_vm.visible
                                  ? 'feather icon-eye-off'
                                  : 'feather icon-eye',"type":_vm.visible ? 'password' : 'text',"error-messages":errors,"label":_vm.$t('label.password'),"required":""},on:{"click:append":function () { return (_vm.visible = !_vm.visible); }},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"password_confirmation","rules":"required|max:50|confirmed:password|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":50,"error-messages":errors,"label":_vm.$t('label.password_confirmation'),"required":"","autocomplete":"off","append-icon":_vm.visibleConfirmation
                                  ? 'feather icon-eye-off'
                                  : 'feather icon-eye',"type":_vm.visibleConfirmation ? 'password' : 'text'},on:{"click:append":function () { return (_vm.visibleConfirmation = !_vm.visibleConfirmation); }},model:{value:(_vm.model.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.model, "passwordConfirm", $$v)},expression:"model.passwordConfirm"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":invalid}},[_c('i',{staticClass:"feather icon-lock mr-1"}),_vm._v(" Modificar contraseña ")])])])])],1)]}}],null,false,1726135581)})],1):_vm._e()])],1)]),_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"card"},[_c('v-card-title',[_c('h5',{staticClass:"card-title mb-0"},[_c('i',{staticClass:"feather icon-home mr-2"}),_vm._v(" Sucursales asignadas ")])]),_c('v-card-text',{staticClass:"scroll-list"},[_c('v-virtual-scroll',{attrs:{"items":_vm.branchOffices,"item-height":50,"height":"340"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"#70c16e"}},[_c('v-avatar',{staticClass:"white--text",attrs:{"size":"56"}},[_vm._v(" "+_vm._s(item.text[0])+_vm._s(item.text[1])+_vm._s(item.text[2])+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)],1)]}}])})],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }