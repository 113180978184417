<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-12 col-lg-12">
            <h4 class="page-title">{{ $t("profile.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard"> {{ $t("profile.home") }} </a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- End Breadcrumbbar -->
      <!-- Start Contentbar -->
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-6 col-xl-6">
            <div class="card pb-5">
              <v-card-title>
                <h5 class="card-title">
                  <i class="feather icon-user mr-2"></i>
                  {{ $t(titleAction) }}
                </h5>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <validation-observer ref="observer1" disabled>
                    <div class="row">
                      <div class="col-md-6">
                        <validation-provider
                          v-slot="{ errors }"
                          name="name"
                          rules="required|max:100"
                        >
                          <v-text-field
                            :disabled="isView"
                            v-model="model.name"
                            :counter="100"
                            :error-messages="errors"
                            :label="$t('label.name')"
                            required
                            autocomplete="off"
                          ></v-text-field>
                        </validation-provider>
                      </div>
                      <div class="col-md-6">
                        <validation-provider
                          v-slot="{ errors }"
                          name="lastname"
                          rules="required|max:200"
                        >
                          <v-text-field
                            :disabled="isView"
                            v-model="model.lastname"
                            :counter="200"
                            :error-messages="errors"
                            :label="$t('label.lastname')"
                            required
                            autocomplete="off"
                          ></v-text-field>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="email"
                          rules="required|max:60|email"
                        >
                          <v-text-field
                            :disabled="isView"
                            v-model="model.email"
                            data-vv-as="emai"
                            type="email"
                            :counter="60"
                            :error-messages="errors"
                            :label="$t('label.e-mail')"
                            required
                            autocomplete="off"
                          ></v-text-field>
                        </validation-provider>
                      </div>
                    </div>
                    <br />
                  </validation-observer>
                </v-container>
              </v-card-text>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />

              <v-card-text>
                <div
                  id="form"
                  v-if="withPermissionName(permissions.PRO_UPD_PASS_01)"
                >
                  <validation-observer
                    ref="observer2"
                    v-slot="{ invalid }"
                    disabled
                  >
                    <form @submit.prevent="updatePassword">
                      <div class="row">
                        <div class="col-md-12">
                          <h5 class="card-title">
                            <i class="feather icon-lock mr-3"></i
                            >{{ $t("profile.change_password") }}
                          </h5>
                        </div>
                      </div>
                      <v-container>
                        <div class="row">
                          <div class="col-md-12">
                            <validation-provider
                              v-slot="{ errors }"
                              name="password"
                              rules="required|max:50|min:6"
                            >
                              <v-text-field
                                autocomplete="new-password"
                                v-model="model.password"
                                :counter="50"
                                :append-icon="
                                  visible
                                    ? 'feather icon-eye-off'
                                    : 'feather icon-eye'
                                "
                                @click:append="() => (visible = !visible)"
                                :type="visible ? 'password' : 'text'"
                                :error-messages="errors"
                                :label="$t('label.password')"
                                required
                              ></v-text-field>
                            </validation-provider>
                          </div>
                          <div class="col-md-12">
                            <validation-provider
                              v-slot="{ errors }"
                              name="password_confirmation"
                              rules="required|max:50|confirmed:password|min:6"
                            >
                              <v-text-field
                                v-model="model.passwordConfirm"
                                :counter="50"
                                :error-messages="errors"
                                :label="$t('label.password_confirmation')"
                                required
                                autocomplete="off"
                                :append-icon="
                                  visibleConfirmation
                                    ? 'feather icon-eye-off'
                                    : 'feather icon-eye'
                                "
                                @click:append="
                                  () =>
                                    (visibleConfirmation = !visibleConfirmation)
                                "
                                :type="
                                  visibleConfirmation ? 'password' : 'text'
                                "
                              >
                              </v-text-field>
                            </validation-provider>
                          </div>

                          <div class="col-md-12 text-right">
                            <button
                              class="btn btn-outline-success"
                              type="submit"
                              :disabled="invalid"
                            >
                              <i class="feather icon-lock mr-1"></i>
                              Modificar contraseña
                            </button>
                          </div>
                        </div>
                      </v-container>
                    </form>
                  </validation-observer>
                </div>
              </v-card-text>
            </div>
          </div>
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <v-card-title>
                <h5 class="card-title mb-0">
                  <i class="feather icon-home mr-2"></i>
                  Sucursales asignadas
                </h5>
              </v-card-title>
              <v-card-text class="scroll-list">
                <v-virtual-scroll
                  :items="branchOffices"
                  :item-height="50"
                  height="340"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item>
                      <v-list-item-avatar color="#70c16e">
                        <v-avatar size="56" class="white--text">
                          {{ item.text[0] }}{{ item.text[1] }}{{ item.text[2] }}
                        </v-avatar>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                        <!-- <v-list-item-subtitle>{{
                          item
                        }}</v-list-item-subtitle> -->
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <!-- <v-container> -->

                <!-- </v-container> -->
              </v-card-text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Rightbar -->
  </div>
  <!-- End Containerbar -->
  <!-- Start js -->
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import { UserRequest } from "@/core/request";
import { USERS } from "@/core/DataTableHeadersLocale";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";

export default {
  data() {
    return {
      title: "profile.title",
      list: [],
      headers: USERS(),
      search: "",
      action: 1,
      sending: false,
      isView: true,
      model: {},
      branchOffices: [],
      branchOffice: {},
      roles: [],
      notifier: {
        notifying: false,
        type: "",
        message: "",
      },
      email: "",
      email_confir: "",
      permissions: PermissionConstants,
      visible: true,
      visibleConfirmation: true,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    AlertMessageComponent,
  },
  created() {
    let infoUser = UtilFront.getDataUser();
    this.branchOffices = infoUser.branchOfficesSimple;
    this.model = {
      ...this.model,
      ...infoUser,
    };
    this.model.branchOffices = this.branchOffices;
  },
  computed: {
    titleAction() {
      return "profile.user_info";
    },
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    alertMail(type, message) {
      this.$refs.alertMessageComponentmail.reloadComponent(type, message);
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    showAction(action) {
      return this.action == action;
    },
    doAction() {
      // this.update();
    },
    clearPassword() {
      this.model.password = "";
      this.model.passwordConfirm = "";
      this.$refs.observer2.reset();
    },
    clearEmail() {
      this.email = "";
      this.$refs.observer3.reset();
    },
    updatePassword() {
      this.sending = true;
      let user = {
        idUser: this.model.idUser,
        password: this.model.password,
        passwordConfirm: this.model.passwordConfirm,
        userBranchOffice: {
          idBranchOffice: this.model.mainBranch,
          idUser: this.model.idUser,
        },
      };
      UserRequest.updatePassword(user)
        .then(() => {
          this.alert("success", "Update_Password_Success_0001");
          this.clearPassword();
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.sending = false;
        });
    },
    updateEmail() {
      this.sending = true;
      let paramss = {
        email: this.email,
        idUser: this.model.idUser,
      };
      UserRequest.updateEmail(paramss)
        .then((res) => {
          let { success } = res.data.data;
          if (success) {
            let dataUser = UtilFront.getDataUser();
            dataUser.email = this.email;
            UtilFront.setDataUser(dataUser);
            this.isView = false;
            this.model.email = this.email;
            this.isView = true;
            this.alertMail("success", "Update_Email_Success_0001");
            this.clearEmail();
          } else {
            this.alertMail("internal");
          }
        })
        .catch((error) => {
          console.log(error);
          this.alertMail("internal");
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>

<style>
.autocomplete {
  overflow-y: scroll;
  height: 385px !important;
}
</style>

<style scoped lang="scss">
::v-deep {
  .scroll-list {
    /* Estilos barra (thumb) de scroll */
    ::-webkit-scrollbar-thumb {
      background: #adb5bd;
      border-radius: 0px;
    }
    ::-webkit-scrollbar-thumb:active {
      background-color: #ccc;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
    }
    /* Estilos track de scroll */
    ::-webkit-scrollbar-track {
      background: #dee2e6;
      border-radius: 0px;
    }
    ::-webkit-scrollbar-track:hover,
    ::-webkit-scrollbar-track:active {
      background: #dee2e6;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 0 10px 16px;
  }
}
</style>
